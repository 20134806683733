/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";

import Swiper from "swiper";
import { Navigation, Pagination, Controller, EffectFade, Autoplay, FreeMode, Scrollbar } from "swiper/modules";


document.addEventListener("DOMContentLoaded", function (event) {
   isElementExist("#header", initHeaderState);
   isElementExist(".header-nav", initNav);
   isElementExist("#carousel", initCarousel);
   isElementExist(".carousel-scroll", initCarouselScroll);
});

function isElementExist(_el, _cb) {
   var elem = document.querySelector(_el);

   if (document.body.contains(elem)) {
      try {
         _cb();
      } catch (e) {
         console.log(e);
      }
   }
}

function initNav() {
   const nav = document.querySelector(".header-nav");
   const navItems = nav.querySelectorAll(".header-nav-item");

   const isTouchDevice = "ontouchstart" in window;

   if (!isTouchDevice) {
      navItems.forEach((item) => {
         item.addEventListener("mouseover", (e) => {
            addActiveClass(e.currentTarget);
         });
         item.addEventListener("mouseout", (e) => {
            removeActiveClass(e.currentTarget);
         });
      });
   }

   function resetActiveStateGlobally() {
      navItems.forEach((item) => {
         item.classList.remove("dropdown-active");
         item.dataset.clicks = 0;
      });
   }

   if (isTouchDevice) {
      document.addEventListener("touchend", (e) => {
         if (!e.target.matches(".header-nav-item, .header-nav-item *")) {
            resetActiveStateGlobally();
         }
      });

      navItems.forEach((item) => {
         item.addEventListener("touchend", (e) => {
            const li = e.currentTarget;
            const hasDropInside = !![...e.currentTarget.childNodes].filter((node) => {
               if (node.classList) {
                  return node.classList.contains("header-nav-item__drop");
               }
            }).length;

            if (!hasDropInside) return;

            const clickCounter = e.currentTarget.dataset.clicks;

            if (parseInt(clickCounter) === 0) {
               resetActiveStateGlobally();

               e.preventDefault();
               li.dataset.clicks = 1;

               addActiveClass(li);
            }
         });
      });
   }

   function addActiveClass(el) {
      el.classList.add("dropdown-active");
   }

   function removeActiveClass(el) {
      el.classList.remove("dropdown-active");
   }
}

function initHeaderState() {
   const header = document.getElementById("header");
   const win = window;

   let prevScrollPos = 0;
   let currentScrollPos = win.scrollY;

   if (currentScrollPos > 0) {
      header.classList.add("scrolled-hidden", "scrolled");
   }

   function scrollY() {
      let currentPos = win.scrollY <= 0 ? 0 : win.scrollY;

      if (currentPos > 0) {
         header.classList.add("scrolled");
      } else {
         header.classList.remove("scrolled");
      }

      if (prevScrollPos < currentPos) {
         header.classList.add("scrolled-hidden");
      } else {
         header.classList.remove("scrolled-hidden");
      }

      prevScrollPos = currentPos <= 0 ? 0 : currentPos;

      // console.log("current ", currentScrollPos);
   }

   win.addEventListener("scroll", scrollY);
}

function initCarousel() {
   const wrapper = document.getElementById("carousel");
   const carouselMain = wrapper.querySelector(".carousel-main");
   const carouselThumb = wrapper.querySelectorAll(".carousel-thumb");

   const nextButton = wrapper.querySelector(".button-next");
   const prevButton = wrapper.querySelector(".button-prev");

   const options = {
      loop: true,
      effect: "fade",
      speed: 500,
      slidesPerView: 1,
      simulateTouch: false,
      autoplay: {
         delay: 4000,
         disableOnInteraction: true,
      },
      fadeEffect: { crossFade: true },
      navigation: {
         nextEl: nextButton,
         prevEl: prevButton,
      },
   };

   const swiperMain = new Swiper(carouselMain, {
      ...options,
      pagination: {
         el: ".swiper-pagination",
         type: "progressbar",
      },
      modules: [Navigation, Pagination, Controller, EffectFade, Autoplay],
   });

   carouselThumb.forEach((carousel) => {
      const swiperInstance = new Swiper(carousel, {
         ...options,
         modules: [Navigation, EffectFade, Controller, Autoplay],
      });
   });
}

function initCarouselScroll() {
   var swiper = new Swiper(".carousel-scroll", {
      slidesPerView: "auto",
      freeMode: true,
      scrollbar: {
         el: ".swiper-scrollbar",
      },
      navigation: {
         nextEl: ".swiper-button-next",
         prevEl: ".swiper-button-prev",
      },
      modules: [Scrollbar, FreeMode, Navigation],
   });
}


/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

// External Popup JS Below
document.addEventListener('DOMContentLoaded', function () {
   const currentDomain = window.location.origin; 
   const externalLinks = document.querySelectorAll(`a[href]:not([href^="${currentDomain}"], [href^="${currentDomain}"], [href^="/"])`);
   const popup = document.getElementById('external-link-popup');
   const popupConfirm = document.getElementById('popup-confirm');
   const popupCancel = document.getElementById('popup-cancel');
   const closePopup = document.getElementById('close-popup');
   const linkUrl = document.getElementById('link-url'); // Get the span element for the link URL
   const body = document.body;

   externalLinks.forEach(function (link) {
       link.addEventListener('click', function (e) {
           e.preventDefault();
           popup.style.display = 'block';
           body.classList.add('no-scroll'); // Add a class to the body to disable scrolling
           linkUrl.textContent = link.href; // Set the span content to the clicked link's URL
       });
   });

   function closePopupFunction() {
       popup.style.display = 'none';
       body.classList.remove('no-scroll'); // Remove the class to enable scrolling
   }

   popupConfirm.addEventListener('click', function () {
       window.location.href = linkUrl.textContent; // Navigate to the URL from the span
   });

   popupCancel.addEventListener('click', closePopupFunction);

   closePopup.addEventListener('click', closePopupFunction);

   // Close the popup when clicking anywhere outside of it (except for the links)
   document.addEventListener('click', function (e) {
       if (getComputedStyle(popup).display === 'block' && e.target !== popup && !popup.contains(e.target) && !Array.from(externalLinks).some(link => link.contains(e.target))) {
           closePopupFunction();
       }
   });

   popup.addEventListener('click', function (e) {
       e.stopPropagation();
   });
});